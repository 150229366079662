import type { DataArrayPartial, Option } from "slim-select/store";
import type { IMarriottLocation } from "../@types";

declare const formAjax: {
    ajaxUrl: string;
};

export async function fetchMarshaCodes(
    selectField: HTMLSelectElement,
    blogId: string,
) {
    const response = await fetch(`${formAjax.ajaxUrl}?action=get_locations`);

    if (!response.ok) {
        throw new Error("Couldn't fetch MARSHA Codes");
    }

    const locations: IMarriottLocation[] = await response.json();

    const { value } = selectField.dataset;

    const selectData: DataArrayPartial = locations.map((location) => {
        let text = location.locationName;
        let locationValue = location.locationName;

        if (blogId === "1") {
            text = `${location.locationNumber} - ${location.locationName}`;
            locationValue = location.locationNumber;
        }

        return {
            text,
            value: locationValue,
            selected: location.locationNumber === value,
            data: {
                postalCode: location.postalCode,
            },
        };
    });

    return selectData;
}

export function setAddress(option: Option) {
    const { postalCode } = option.data;
    const postalCodeInput = document.querySelector<HTMLInputElement>(
        ".location-address .address_zip input",
    );

    if (postalCodeInput) {
        postalCodeInput.value = postalCode;
    }
}

export function searchLocations(
    search: string,
    locations: any[],
): Promise<DataArrayPartial> {
    const foundLocations: DataArrayPartial = locations.filter((location) =>
        location.text.toLowerCase().includes(search),
    );

    return new Promise((resolve) => resolve(foundLocations));
}
