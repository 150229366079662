import SlimSelect from "slim-select";
import {
    fetchMarshaCodes,
    searchLocations,
    setAddress,
} from "./modules/location";

async function createMarshaSelect() {
    const selectField =
        document.querySelector<HTMLSelectElement>(".marsha-codes");

    if (!selectField) {
        throw new Error("MARSHA select input doesn't exist");
    }

    const blogId = document.body.dataset.blogId ?? "0";

    const marshaCodesPromise = fetchMarshaCodes(selectField, blogId);

    let searchType = "property";

    if (blogId === "1") {
        searchType = "MARSHA Code";
    }

    const selector = new SlimSelect({
        select: ".marsha-codes",
        settings: {
            placeholderText: `Search for your ${searchType}`,
            searchText: `Start typing to search for your ${searchType}`,
        },
        events: {
            afterChange(selected) {
                setAddress(selected[0]);
            },
        },
    });

    const marshaCodes = await marshaCodesPromise;

    let typingTimer: NodeJS.Timeout;
    selector.events.search = (search) => {
        return new Promise((resolve, reject) => {
            if (search.length === 0) {
                return reject(new Error("Please enter a valid search"));
            }

            clearTimeout(typingTimer);

            typingTimer = setTimeout(() => {
                resolve(searchLocations(search.toLowerCase(), marshaCodes));
            }, 500);
        });
    };
}

function formatAmounts(event: Event) {
    const input = event.target;

    if (!(input instanceof HTMLInputElement)) {
        return;
    }

    const value = parseFloat(input.value.replaceAll(",", ""));

    if (isNaN(value)) {
        return;
    }

    input.value = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
    }).format(value);
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
jQuery(document).on("gform_post_render", async () => {
    const donationList = document.querySelector(".gfield_list_groups");

    donationList?.addEventListener("change", formatAmounts);

    await createMarshaSelect();
});
